import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";

import { useDeliveriesStore, useModalStore } from "@/stores/hooks";

const Title = styled.h4`
  margin: 0;
`;

const ButtonWrap = styled.div`
  margin-top: 10px;
  text-align: right;
`;
const ModalButton = styled(Button)`
  margin-left: 5px;
`;

const Dl = styled.dl`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
`;
const Dt = styled.dt`
  width: 140px;
  line-height: 1;
`;
const Dd = styled.dd`
  margin: 0;
  line-height: 1;
  flex: 1;
  word-break: break-all;
`;

const Image = styled.img`
  width: 100%;
`;

const StatusLogsWrap = styled.ul`
  margin: 0;
  padding-left: 15px;
`;

const StatusLog = styled.li``;

const DeliveryStatusLogs = memo(({ bookId }) => {
  const { statusLogs } = useDeliveriesStore().state;
  const { ...actions } = useDeliveriesStore();

  const fetchStatusLogsById = useCallback(async () => {
    try {
      await actions.fetchStatusLogsById(bookId);
    } catch (e) {
      // console.log(e);
    }
  }, [actions.fetchStatusLogsById, bookId]);

  useEffect(() => {
    fetchStatusLogsById(bookId);
  }, []);

  return (
    <StatusLogsWrap>
      {statusLogs.map((log, index) => {
        return (
          <StatusLog key={index}>
            {log.renderCreatedAt()} {log.beforeString} → {log.afterString}
          </StatusLog>
        );
      })}
    </StatusLogsWrap>
  );
});

export default function DeliveryModal({ bookId, onAfterSubmit }) {
  const { delivery, sentBackLogs } = useDeliveriesStore().state;
  const { ...actions } = useDeliveriesStore();

  const { closeModal } = useModalStore();

  const [loaded, setLoaded] = useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchByIdOnTheFly(bookId);
    fetchSentBackLogsById(bookId);
  }, []);

  const fetchByIdOnTheFly = useCallback(async () => {
    try {
      await actions.fetchByIdOnTheFly(bookId);
      setLoaded(true);
    } catch (e) {
      window.alert(`배송 조회에 실패하였습니다.\n${e.message}`);

      // console.log(e);
    }
  }, [actions.fetchByIdOnTheFly]);

  const fetchSentBackLogsById = useCallback(async () => {
    try {
      await actions.fetchSentBackLogsById(bookId);
    } catch (e) {
      console.log(e);
    }
  }, [actions.fetchSentBackLogsById]);

  const cancelDelivery = useCallback(async () => {
    if (
      window.confirm(
        `예약번호 ${bookId}건을 취소처리하시겠습니까? 취소처리하시면 해당 건은 더 이상 배송진행을 하실 수 없습니다.`,
      )
    ) {
      try {
        await actions.cancel(bookId);

        onAfterSubmit();
        closeModal();
      } catch (e) {
        window.alert("취소 처리에 실패했습니다. 다시 시도해주세요.");
      }
    }
  }, [actions.cancel, bookId]);

  const returnDelivery = useCallback(() => {
    closeModal();
    history.push(`/deliveries-new?type=return&bookId=${bookId}`);
  }, [history, bookId]);

  return (
    loaded && (
      <Modal size="lg" backdrop={true}>
        <Modal.Header closeButton={true}>
          <Title>배송 정보</Title>
        </Modal.Header>
        <Modal.Body>
          <Dl>
            <Dt>예약번호 (배송상태)</Dt>
            <Dd>
              {delivery.bookId} ({delivery.renderStatus()})
            </Dd>
          </Dl>
          <Dl>
            <Dt>사측 주문번호</Dt>
            <Dd>{delivery.orderIdFromCorp}</Dd>
          </Dl>
          {/* <Dl>
            <Dt>예약일시</Dt>
            <Dd>{delivery.renderBookedAt()}</Dd>
          </Dl> */}
          <Dl>
            <Dt>접수일시</Dt>
            <Dd>{delivery.renderReceiptDate()}</Dd>
          </Dl>

          {delivery.isPossibleToShowDeliveryRider && (
            <Dl>
              <Dt>배송기사 연락처</Dt>
              <Dd>
                {delivery.deliveryRider
                  ? `${delivery.deliveryRiderName} 기사님(${delivery.deliveryRiderMobile})`
                  : ""}
              </Dd>
            </Dl>
          )}
          <Dl>
            <Dt>접수점</Dt>
            <Dd>{delivery.spotName}</Dd>
          </Dl>
          <Dl>
            <Dt>접수점 연락처</Dt>
            <Dd>{delivery.spotContact}</Dd>
          </Dl>
          <Dl>
            <Dt>송하인명</Dt>
            <Dd>{delivery.senderName}</Dd>
          </Dl>
          <Dl>
            <Dt>송하인 연락처</Dt>
            <Dd>{delivery.senderMobile}</Dd>
          </Dl>
          <Dl>
            <Dt>송하인 연락처2</Dt>
            <Dd>{delivery.senderMobile2}</Dd>
          </Dl>
          <Dl>
            <Dt>송하인 주소</Dt>
            <Dd>
              {delivery.senderAddress} {delivery.senderAddressDetail}
            </Dd>
          </Dl>
          <Dl>
            <Dt>수하인명</Dt>
            <Dd>{delivery.receiverName}</Dd>
          </Dl>
          <Dl>
            <Dt>수하인 연락처</Dt>
            <Dd>{delivery.receiverMobile}</Dd>
          </Dl>
          <Dl>
            <Dt>수하인 연락처 2</Dt>
            <Dd>{delivery.receiverMobile2}</Dd>
          </Dl>
          <Dl>
            <Dt>수하인 주소</Dt>
            <Dd>
              {delivery.receiverAddress} {delivery.receiverAddressDetail}
            </Dd>
          </Dl>
          <Dl>
            <Dt>도착동</Dt>
            <Dd>{delivery.receiverDongName}</Dd>
          </Dl>
          <Dl>
            <Dt>물품 분류</Dt>
            <Dd>{delivery.productCategory}</Dd>
          </Dl>
          <Dl>
            <Dt>물품명</Dt>
            <Dd>{delivery.productName}</Dd>
          </Dl>
          <Dl>
            <Dt>물품가격</Dt>
            <Dd>{delivery.productPrice}</Dd>
          </Dl>
          <Dl>
            <Dt>고객요청사항</Dt>
            <Dd>{delivery.memoFromCustomer}</Dd>
          </Dl>
          <Dl>
            <Dt>기타사항1</Dt>
            <Dd>{delivery.etc1}</Dd>
          </Dl>
          <Dl>
            <Dt>기타사항2</Dt>
            <Dd>{delivery.etc2}</Dd>
          </Dl>
          <Dl>
            <Dt>기타사항3</Dt>
            <Dd>{delivery.etc3}</Dd>
          </Dl>
          <Dl>
            <Dt>반송일시및사유</Dt>
            <Dd>
              {sentBackLogs?.length > 0
                ? `${sentBackLogs[0].renderCreatedAt()} ${
                    sentBackLogs[0].reason
                  }`
                : ""}
            </Dd>
          </Dl>
          <Dl>
            <Dt>분실일시및사유</Dt>
            <Dd>
              {delivery.renderLostDate()} {delivery.lostReason}
            </Dd>
          </Dl>
          <Dl>
            <Dt>수량값</Dt>
            <Dd>{delivery.productCount}</Dd>
          </Dl>
          <Dl>
            <Dt>수취시 서명 이미지</Dt>
            <Dd>
              {delivery.signImageLocation && (
                <Image
                  src={delivery.signImageLocation}
                  alt="수취시 서명 이미지"
                />
              )}
            </Dd>
          </Dl>
          <Dl>
            <Dt>미수취 촬영 이미지</Dt>
            <Dd>
              {delivery.notReceivedImageLocation && (
                <Image
                  src={delivery.notReceivedImageLocation}
                  alt="미수취 촬영 이미지"
                />
              )}
            </Dd>
          </Dl>
          <Dl>
            <Dt>반품수거 촬영 이미지</Dt>
            <Dd>
              {delivery.pickupCompletedImage && (
                <Image
                  src={delivery.pickupCompletedImage}
                  alt="반품수거 촬영 이미지"
                />
              )}
            </Dd>
          </Dl>
          <Dl>
            <Dt>최근 미배송</Dt>
            <Dd>{/** TODO */}</Dd>
          </Dl>
          <Dl>
            <Dt>배송연기 기록</Dt>
            <Dd>{delivery.renderPostponedDate()}</Dd>
          </Dl>

          <Dl>
            <Dt>취소사유</Dt>
            <Dd>{delivery.canceledReason}</Dd>
          </Dl>

          <Dl>
            <Dt>배송로그</Dt>
            <Dd>
              <DeliveryStatusLogs bookId={bookId} />
            </Dd>
          </Dl>
          <ButtonWrap>
            {delivery.isPossibleToCancel && (
              <ModalButton onClick={cancelDelivery} type="button" width="120px">
                취소 신청
              </ModalButton>
            )}

            {delivery.isPossibleToReturn && (
              <ModalButton onClick={returnDelivery} type="button" width="120px">
                반품 신청
              </ModalButton>
            )}

            <ModalButton
              onClick={closeModal}
              type="button"
              variant="outline-secondary"
              width="120px"
            >
              닫기
            </ModalButton>
          </ButtonWrap>
        </Modal.Body>
      </Modal>
    )
  );
}
