import {
  addMinutes,
  differenceInMinutes,
  isSameDay,
  getHours,
  getMinutes,
} from "date-fns";
import { formatDate, getDateByTimeZone } from "@/lib/date";

import { SPOT_CODE_10585 } from "./DeliveryForWaybill";

export const DELIVERY_TYPE_NORMAL = "1";
export const DELIVERY_TYPE_PICKUP = "2";
export const DELIVERY_TYPE_RETURN = "3";

export const DELIVERY_STATUS_0 = "DELIVERY_STATUS_RECEIPT";
export const DELIVERY_STATUS_1 = "DELIVERY_STATUS_PICKUP_SCHEDULED";
export const DELIVERY_STATUS_2 = "DELIVERY_STATUS_PICKUP_COMPLETED";
export const DELIVERY_STATUS_3 = "DELIVERY_STATUS_WAREHOUSED";
export const DELIVERY_STATUS_4 = "DELIVERY_STATUS_DELIVERY_STARTED";
export const DELIVERY_STATUS_5 = "DELIVERY_STATUS_DELIVERED";
export const DELIVERY_STATUS_6 = "DELIVERY_STATUS_DELIVERY_SENT_BACK";
export const DELIVERY_STATUS_7 = "DELIVERY_STATUS_DELIVERY_LOST";
export const DELIVERY_STATUS_8 = "DELIVERY_RIDER_ALLOCATED"; // 배송배차
export const DELIVERY_STATUS_9 = "ADDRESS_NOT_SUPPORTED";
export const DELIVERY_STATUS_10 = "ACCIDENT";
export const DELIVERY_STATUS_11 = "CANCELED";
export const DELIVERY_STATUS_12 = "DELIVERY_POSTPONED";

export const getPointToArray = (point) => {
  if (point && point.indexOf("POINT") > -1) {
    let pointArray = point.substring(6, point.length - 1).split(" ");

    return pointArray;
  } else {
    return [0, 0];
  }
};

export const parseDeliveryStatus = (status) => {
  switch (Number(status)) {
    case 0:
      return DELIVERY_STATUS_0;
    case 1:
      return DELIVERY_STATUS_1;
    case 2:
      return DELIVERY_STATUS_2;
    case 3:
      return DELIVERY_STATUS_3;
    case 4:
      return DELIVERY_STATUS_4;
    case 5:
      return DELIVERY_STATUS_5;
    case 6:
      return DELIVERY_STATUS_6;
    case 7:
      return DELIVERY_STATUS_7;
    case 8:
      return DELIVERY_STATUS_8;
    case 9:
      return DELIVERY_STATUS_9;
    case 10:
      return DELIVERY_STATUS_10;
    case 11:
      return DELIVERY_STATUS_11;
    case 12:
      return DELIVERY_STATUS_12;
    default:
      return "알수없음";
  }
};

export const renderStatus = (status) => {
  switch (status) {
    case DELIVERY_STATUS_1:
      return "수거지정";
    case DELIVERY_STATUS_2:
      return "수거완료";
    case DELIVERY_STATUS_3:
      return "입고완료";
    case DELIVERY_STATUS_4:
      return "배송출발";
    case DELIVERY_STATUS_5:
      return "배송완료";
    case DELIVERY_STATUS_6:
      return "반송완료";
    case DELIVERY_STATUS_7:
      return "분실완료";
    case DELIVERY_STATUS_8:
      return "배송배차";
    case DELIVERY_STATUS_9:
      return "배송불가";
    case DELIVERY_STATUS_10:
      return "사고";
    case DELIVERY_STATUS_11:
      return "취소";
    case DELIVERY_STATUS_12:
      return "배송연기";

    default:
      return "접수";
  }
};

export default class Delivery {
  accidentAt = null;
  accidentReason = "";
  addressNotSupported = false;
  bookId = "";
  bookedAt = null;
  canceledAt = null;
  canceledReason = "";
  corpUser = null;
  deliveryAllocatedDate = null;
  deliveryCompletedDate = null;
  deliveryRider = null;
  deliveryStatusLogs = [];
  delayedDeliveries = [];
  etc1 = "";
  etc2 = "";
  etc3 = "";
  etc4 = "{}";
  notReceivedImageLocationOld = "";
  order = null;
  orderIdFromCorp = "";
  prepaid = null;
  pickupDateCompleted = null;
  pickupDateScheduled = null;
  pickupRider = null;
  postponedDate = null;
  printed = false;
  productCategory = null;
  productCount = null;
  productName = null;
  productPrice = 0;
  reallocatedRider = null;
  reallocationRequestedAt = null;
  receiptDate = null;
  receiverAddress = null;
  receiverAddressRoad = null;
  receiverAllocationGroupName = null;
  receiverAllocationGroupNameOnWeekdays = null;
  receiverAllocationGroupNameOnWeekends = null;
  receiverDong = null;
  receiverSigungu = null;
  receiverSido = null;
  receiverMobile = "";
  receiverMobile2 = "";
  receiverPoint = null;
  receiverPostalCode = null;
  receiverName = null;
  releasedAt = null;
  requestedDeliveryTime = null;
  senderDong = null;
  senderMobile = "";
  senderMobile2 = "";
  senderName = null;
  senderPostalCode = null;
  sentBackLogs = null;
  sentBackDate = null;
  signImageLocationOld = "";
  spot = null;
  spotId = null;
  status = DELIVERY_STATUS_0;
  time = null;
  warehousedAt = null;
  type = DELIVERY_TYPE_NORMAL;
  unrefinedAddress = null;
  lostDate = null;
  // canceled
  isAccident = false;
  isCanceled = false;

  constructor(delivery) {
    this.bookId = delivery.bookId;
    this.addressNotSupported = delivery.addressNotSupported;
    this.status = parseDeliveryStatus(delivery.status);
    this.bookedAt = delivery.bookedAt;
    this.deliveryAllocatedDate = delivery.deliveryAllocatedDate;
    this.deliveryCompletedDate = delivery.deliveryCompletedDate;
    this.deliveryRider = delivery.deliveryRider;
    this.deliveryStatusLogs = delivery.deliveryStatusLogs;
    this.delayedDeliveries = delivery.delayedDeliveries;
    this.corpUser = delivery.corpUser;
    this.etc1 = delivery.etc1;
    this.etc2 = delivery.etc2;
    this.etc3 = delivery.etc3;
    this.etc4 = delivery.etc4;
    this.memoFromCustomer = delivery.memoFromCustomer;
    this.notReceivedImageLocationOld = delivery.notReceivedImageLocation;
    this.order = delivery.order;
    this.orderIdFromCorp = delivery.orderIdFromCorp;
    this.postponedDate = delivery.postponedDate;
    this.prepaid = delivery.prepaid;
    this.pickupDateCompleted = delivery.pickupDateCompleted;
    this.pickupRider = delivery.pickupRider;
    this.pickupDateScheduled = delivery.pickupDateScheduled;
    this.reallocationRequestedAt = delivery.reallocationRequestedAt;
    this.reallocatedRider = delivery.reallocatedRider;
    this.receiptDate = delivery.receiptDate;
    this.receiverAddress = delivery.receiverAddress;
    this.receiverAddressRoad = delivery.receiverAddressRoad;
    this.receiverAddressDetail = delivery.receiverAddressDetail;
    this.receiverAllocationGroupName = delivery.receiverAllocationGroupName;
    this.receiverAllocationGroupNameOnWeekdays =
      delivery.receiverAllocationGroupNameOnWeekdays;
    this.receiverAllocationGroupNameOnWeekends =
      delivery.receiverAllocationGroupNameOnWeekends;
    this.receiverDong = delivery.receiverDongObj;
    this.receiverMobile = delivery.receiverMobile;
    this.receiverMobile2 = delivery.receiverMobile2;
    this.receiverName = delivery.receiverName;
    this.receiverPoint = delivery.receiverPoint;
    this.receiverPostalCode = delivery.receiverPostalCode;
    this.receiverSigungu =
      (delivery.receiverDongObj && delivery.receiverDongObj.sigungu) || null;
    this.receiverSido =
      (this.receiverSigungu && this.receiverSigungu.sido) || null;
    this.releasedAt = delivery.releasedAt;
    this.requestedDeliveryTime = delivery.requestedDeliveryTime;
    this.printed = delivery.printed;
    this.productCategory = delivery.productCategory;
    this.productCount = delivery.productCount;
    this.productName = delivery.productName;
    this.productPrice = delivery.productPrice;
    this.senderAddress = delivery.senderAddress;
    this.senderAddressDetail = delivery.senderAddressDetail;
    this.senderDong = delivery.senderDongObj;
    this.senderMobile = delivery.senderMobile;
    this.senderMobile2 = delivery.senderMobile2;
    this.senderName = delivery.senderName;
    this.senderPostalCode = delivery.senderPostalCode;
    this.signImageLocationOld = delivery.signImageLocation;
    this.sentBackLogs = delivery.sentBackLogs;
    this.sentBackDate = delivery.sentBackDate;
    this.spot = delivery.spot || null;
    this.spotId = delivery.spotId || null;
    this.time = delivery.time || null;
    this.type = delivery.type || DELIVERY_TYPE_NORMAL;
    this.warehousedAt = delivery.warehousedAt;

    // canceled
    this.isAccident = !!delivery.isAccident;
    this.accidentAt = delivery.accidentAt || null;
    this.accidentReason = delivery.accidentReason || null;
    this.isCanceled = !!delivery.isCanceled;
    this.canceledAt = delivery.canceledAt || null;
    this.canceledReason = delivery.canceledReason || null;

    this.unrefinedAddress = delivery.unrefinedAddress;

    this.lostDate = delivery._lostDate;
  }

  get addressApiType() {
    return this.etc4Obj ? this.etc4Obj.addressApiType : "";
  }

  get completedLocation() {
    return this.etc4Obj ? this.etc4Obj.completedLocation : "";
  }

  get customerAddress() {
    return this.etc4Obj ? this.etc4Obj.customerAddress : "";
  }

  get customerAddressDetail() {
    return this.etc4Obj ? this.etc4Obj.customerAddressDetail : "";
  }

  get customerAddressPostalCode() {
    return this.etc4Obj ? this.etc4Obj.customerAddressPostalCode : "";
  }

  get deliveryDoneLatitude() {
    return this.etc4Obj ? this.etc4Obj.deliveryDoneLatitude : "";
  }

  get deliveryDoneLongitude() {
    return this.etc4Obj ? this.etc4Obj.deliveryDoneLongitude : "";
  }

  get deliveryLostLatitude() {
    return this.etc4Obj ? this.etc4Obj.deliveryLostLatitude : "";
  }

  get deliveryLostLongitude() {
    return this.etc4Obj ? this.etc4Obj.deliveryLostLongitude : "";
  }

  get deliveryPlace() {
    return this.etc4Obj ? this.etc4Obj.deliveryPlace : "";
  }

  get deliveryPlacedTime() {
    return this.etc4Obj ? this.etc4Obj.deliveryPlacedTime : "";
  }

  get deliveryRiderName() {
    return this.deliveryRider ? this.deliveryRider.name : "";
  }

  get deliveryRiderMobile() {
    return this.deliveryRider ? this.deliveryRider.mobile1 : "";
  }

  get deliverySentBackLatitude() {
    return this.etc4Obj ? this.etc4Obj.deliverySentBackLatitude : "";
  }

  get deliverySentBackLongitude() {
    return this.etc4Obj ? this.etc4Obj.deliverySentBackLongitude : "";
  }

  get etc4Obj() {
    try {
      return this.etc4 && JSON.parse(this.etc4);
    } catch (e) {
      return {};
    }
  }

  get frontdoorPassword() {
    if (this.frontdoorPasswordByClient) {
      return `${this.frontdoorPasswordByClient} ${
        this.frontdoorPasswordByCorp ? `/ ${this.frontdoorPasswordByCorp}` : ""
      }`;
    } else {
      return this.frontdoorPasswordByCorp || null;
    }
  }

  get frontdoorPasswordByClient() {
    return this.etc4Obj ? this.etc4Obj.frontdoorPasswordByClient : "";
  }

  get frontdoorPasswordByCorp() {
    return this.etc4Obj ? this.etc4Obj.frontdoorPasswordByCorp : "";
  }

  get lostReason() {
    return this.etc4Obj && this.etc4Obj.lostReason
      ? this.etc4Obj.lostReason
      : "";
  }

  get notReceivedImageLocation() {
    return this.etc4Obj && this.etc4Obj.notReceivedImageLocation
      ? this.etc4Obj.notReceivedImageLocation
      : this.notReceivedImageLocationOld;
  }

  get pickupRiderName() {
    return this.pickupRider ? this.pickupRider.name : "";
  }

  get reallocatedRiderName() {
    return this.reallocatedRider ? this.reallocatedRider.name : "";
  }

  get pickupCompletedImage() {
    return this.etc4Obj?.pickupCompletedImage || null;
  }

  get prepaidText() {
    return this.prepaid ? "선불" : "후불";
  }

  get receiverAddressObj() {
    return this.etc4Obj ? this.etc4Obj.receiverAddressObj : null;
  }

  get receiverAddressPostalCode() {
    return this.receiverAddressObj ? this.receiverAddressObj.postalCode : null;
  }

  get receiverAddressRoadWithoutSigungu() {
    if (this.receiverAddressRoad) {
      let receiverAddressRoadArr = this.receiverAddressRoad.split(" ");

      if (receiverAddressRoadArr.length > 2) {
        return receiverAddressRoadArr
          .slice(2, receiverAddressRoadArr.length)
          .join(" ");
      } else {
        return this.receiverAddressRoad;
      }
    } else {
      return "";
    }
  }

  get receiverDongName() {
    return this.receiverDong ? this.receiverDong.name : "";
  }

  get receiverSigunguName() {
    return this.receiverSigungu ? this.receiverSigungu.name : "";
  }

  get receiverSidoName() {
    return this.receiverSido ? this.receiverSido.name : "";
  }

  get receiverLnglat() {
    return {
      longitude: getPointToArray(this.receiverPoint)[0],
      latitude: getPointToArray(this.receiverPoint)[1],
    };
  }

  get sendedCompletedSms() {
    return this.etc4Obj ? this.etc4Obj.sendedCompletedSms : false;
  }

  get senderAddressObj() {
    if (this.etc4Obj && this.etc4Obj.senderAddressObj) {
      if (typeof this.etc4Obj.senderAddressObj === "string") {
        return JSON.parse(this.etc4Obj.senderAddressObj);
      } else if (typeof this.etc4Obj.senderAddressObj === "object") {
        return this.etc4Obj.senderAddressObj;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  get senderAddressPostalCode() {
    return this.senderAddressObj ? this.senderAddressObj.postalCode : "";
  }

  get senderDongName() {
    return this.senderDong && this.senderDong.name;
  }

  get sentBackReason() {
    return this.etc4Obj && this.etc4Obj.sentBackReason
      ? this.etc4Obj.sentBackReason
      : "";
  }

  get signImageLocation() {
    return this.etc4Obj && this.etc4Obj.signImageLocation
      ? this.etc4Obj.signImageLocation
      : this.signImageLocationOld;
  }

  get spotName() {
    return this.spot && this.spot.name;
  }

  get spotContact() {
    return this.spot && this.spot.contact;
  }

  get isPossibleToCancel() {
    return (
      !this.isCanceled &&
      (this.status === DELIVERY_STATUS_1 || this.status === DELIVERY_STATUS_0)
    );
  }

  get isPossibleToResetPickupStatus() {
    return (
      !this.isAccident && !this.isCanceled && this.status === DELIVERY_STATUS_1
    );
  }

  get isPossibleToRestoreAccident() {
    return !!this.isAccident;
  }

  get isPossibleToRestoreCancel() {
    return !!this.isCanceled;
  }

  get isPossibleToReturn() {
    return (
      this.type !== DELIVERY_TYPE_RETURN && this.status === DELIVERY_STATUS_5
    );
  }

  get isPossibleToSetDeliveryCompletedStatus() {
    return (
      !this.isAccident && !this.isCanceled && this.status === DELIVERY_STATUS_4
    );
  }

  get isPossibleToSetPreviousStatus() {
    return !this.isAccident && !this.isCanceled;
  }

  get isPossibleToSetDeliveryStarted() {
    return (
      !this.isAccident &&
      !this.isCanceled &&
      (this.status === DELIVERY_STATUS_3 ||
        this.status === DELIVERY_STATUS_8) &&
      this.deliveryRider &&
      !this.reallocatedRider
    );
  }

  get isPossibleToSetWarehousedStatus() {
    return (
      !this.isAccident && !this.isCanceled && this.status === DELIVERY_STATUS_2
    );
  }

  get isPossibleToSetWarehousedStatusBeforePickup() {
    return (
      !this.isAccident && !this.isCanceled && this.status === DELIVERY_STATUS_1
    );
  }

  get isPossibleToShowDeliveryRider() {
    return this.spot && this.spot.code === SPOT_CODE_10585;
  }

  get updateDeliveryCompletedStateByAdmin() {
    return this.etc4Obj && this.etc4Obj.updateDeliveryCompletedStateByAdmin;
  }

  get updateDeliveryCompletedStateBy() {
    return this.etc4Obj && this.etc4Obj.updateDeliveryCompletedStateBy;
  }

  get updateDeliveryCompletedStateAt() {
    return this.etc4Obj && this.etc4Obj.updateDeliveryCompletedStateAt;
  }

  renderAccidentAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.accidentAt &&
      formatDate(getDateByTimeZone(this.accidentAt), dateFormat)
    );
  }

  renderBookedAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.bookedAt && formatDate(getDateByTimeZone(this.bookedAt), dateFormat)
    );
  }

  renderBookIdPrefix() {
    // if (this.status === DELIVERY_STATUS_7) {
    //   return "(분)";
    // }

    if (this.type === DELIVERY_TYPE_RETURN) {
      return "(반)";
    } else if (this.type === DELIVERY_TYPE_PICKUP) {
      return "(픽)";
    } else {
      return "";
    }
  }

  renderCanceledAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.canceledAt &&
      formatDate(getDateByTimeZone(this.canceledAt), dateFormat)
    );
  }

  renderDelayedDeliveries() {
    if (this.delayedDeliveries?.length > 0) {
      return this.delayedDeliveries
        .map(
          (dd) =>
            `${formatDate(getDateByTimeZone(dd.createdAt))}: ${dd.reason}`,
        )
        .join("\n");
    } else {
      return "";
    }
  }

  renderDeliveryCompletedDate(dateFormat = "MM/dd HH:mm") {
    if (this.status === DELIVERY_STATUS_7) {
      return (
        this.lostDate &&
        `${formatDate(getDateByTimeZone(this.lostDate), dateFormat)}(분)`
      );
      // return (
      //   this.renderDeliveryStatusLogsCreatedAt(7) &&
      //   `${formatDate(
      //     getDateByTimeZone(this.renderDeliveryStatusLogsCreatedAt(7)),
      //     dateFormat,
      //   )}(분)`
      // );
    } else if (this.status === DELIVERY_STATUS_6) {
      return (
        this.sentBackDate &&
        `${formatDate(getDateByTimeZone(this.sentBackDate), dateFormat)}(반)`
      );
      // return (
      //   this.renderDeliveryStatusLogsCreatedAt(6) &&
      //   `${formatDate(
      //     getDateByTimeZone(this.renderDeliveryStatusLogsCreatedAt(6)),
      //     dateFormat,
      //   )}(반)`
      // );
    } else {
      return (
        this.deliveryCompletedDate &&
        formatDate(getDateByTimeZone(this.deliveryCompletedDate), dateFormat)
      );
    }
  }

  // renderDeliveryLostDate(dateFormat = "MM/dd HH:mm") {
  //   return (
  //     this.renderDeliveryStatusLogsCreatedAt(7) &&
  //     formatDate(
  //       getDateByTimeZone(this.renderDeliveryStatusLogsCreatedAt(7)),
  //       dateFormat,
  //     )
  //   );
  // }

  // renderDeliverySentBackDate(dateFormat = "MM/dd HH:mm") {
  //   return (
  //     this.renderDeliveryStatusLogsCreatedAt(6) &&
  //     formatDate(
  //       getDateByTimeZone(this.renderDeliveryStatusLogsCreatedAt(6)),
  //       dateFormat,
  //     )
  //   );
  // }

  renderDeliveryStatusLogsCreatedAt(after = 7) {
    const deliveryStatusLog =
      this.deliveryStatusLogs &&
      this.deliveryStatusLogs.find((el) => el.after === after);

    if (deliveryStatusLog) {
      return deliveryStatusLog.createdAt;
    } else {
      return null;
    }
  }

  renderPickupDateCompleted(dateFormat = "MM/dd HH:mm") {
    return (
      this.pickupDateCompleted &&
      formatDate(getDateByTimeZone(this.pickupDateCompleted), dateFormat)
    );
  }

  renderPickupDateScheduled(dateFormat = "MM/dd HH:mm") {
    return (
      this.pickupDateScheduled &&
      formatDate(getDateByTimeZone(this.pickupDateScheduled), dateFormat)
    );
  }

  renderReallocationRequestedAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.reallocationRequestedAt &&
      formatDate(getDateByTimeZone(this.reallocationRequestedAt), dateFormat)
    );
  }

  renderReceiptDate(dateFormat = "MM/dd HH:mm") {
    return (
      this.receiptDate &&
      formatDate(getDateByTimeZone(this.receiptDate), dateFormat)
    );
  }

  renderDeliveryAllocatedDate(dateFormat = "MM/dd HH:mm") {
    return (
      this.deliveryAllocatedDate &&
      formatDate(getDateByTimeZone(this.deliveryAllocatedDate), dateFormat)
    );
  }

  renderLostDate(dateFormat = "MM/dd HH:mm") {
    return (
      this.lostDate && formatDate(getDateByTimeZone(this.lostDate), dateFormat)
    );
  }

  renderPostponedDate(dateFormat = "MM/dd HH:mm") {
    return (
      this.postponedDate &&
      formatDate(getDateByTimeZone(this.postponedDate), dateFormat)
    );
  }

  renderReleasedAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.releasedAt &&
      formatDate(getDateByTimeZone(this.releasedAt), dateFormat)
    );
  }

  renderSentBackDate(dateFormat = "MM/dd HH:mm") {
    return (
      this.sentBackDate &&
      formatDate(getDateByTimeZone(this.sentBackDate), dateFormat)
    );
  }

  renderEstimatedTime() {
    if (this.status === DELIVERY_STATUS_4 && this.order) {
      const current = new Date();

      const from = addMinutes(new Date(), this.order * 10);
      const to = addMinutes(new Date(), this.order * 10 + 120);

      let displayFrom = formatDate(from, "H:m");
      if (
        !isSameDay(current, from) ||
        (getHours(from) >= 22 && getMinutes(from) >= 30)
      ) {
        displayFrom = "22:30";
      }

      let displayTo = formatDate(to, "H:m");
      if (
        !isSameDay(current, to) ||
        (getHours(to) >= 22 && getMinutes(to) >= 30)
      ) {
        displayTo = "22:30";
      }

      return `${displayFrom} ~ ${displayTo}(${this.order})`;
    } else {
      return "";
    }
  }

  renderRequestedDeliveryTime(dateFormat = "MM/dd HH:mm") {
    return (
      this.requestedDeliveryTime &&
      formatDate(getDateByTimeZone(this.requestedDeliveryTime), dateFormat)
    );
  }

  renderStatus() {
    if (this.addressNotSupported) {
      return "배송불가";
    } else {
      switch (this.status) {
        case DELIVERY_STATUS_1:
          return "수거지정";
        case DELIVERY_STATUS_2:
          return "수거완료";
        case DELIVERY_STATUS_3:
          return "입고완료";
        case DELIVERY_STATUS_4:
          return "배송출발";
        case DELIVERY_STATUS_5:
          return "배송완료";
        case DELIVERY_STATUS_6:
          return "반송완료";
        case DELIVERY_STATUS_7:
          return "분실완료";
        case DELIVERY_STATUS_8:
          return "배송배차";
        case DELIVERY_STATUS_9:
          return "배송불가";
        case DELIVERY_STATUS_10:
          return "사고";
        case DELIVERY_STATUS_11:
          return "취소";
        case DELIVERY_STATUS_12:
          return "배송연기";
        default:
          return "접수";
      }
    }
  }

  renderTime() {
    let total = 0;

    if (this.releasedAt && this.deliveryCompletedDate) {
      total = differenceInMinutes(
        new Date(this.deliveryCompletedDate),
        new Date(this.releasedAt),
      );
    } else {
      return "";
    }

    const time = parseInt(total / 60);
    const minute = total % 60;

    return `${time < 10 ? "0" : ""}${time}:${minute < 10 ? "0" : ""}${minute}`;
  }

  renderWarehousedAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.warehousedAt &&
      formatDate(getDateByTimeZone(this.warehousedAt), dateFormat)
    );
  }
}
