import React, { useEffect } from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import _noop from "lodash/noop";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";

import { useLoadingStore, useModalStore, useSpotsStore } from "@/stores/hooks";

const Title = styled.h4`
  margin: 0;
`;

const FormGroup = styled(Form.Group)`
  margin-bottom: 5px;
`;

const FormLabel = styled(Form.Label)`
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
`;

const FormCol = styled(Col)``;

const FormControl = styled(Form.Control)`
  width: ${({ width }) => width || "100%"};
`;

const FormText = styled(Form.Text)`
  color: #ea2845;
`;

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: #ea2845;
  font-size: 12px;
`;

export default function ExcelSchemeModal({ spot, onAfterSubmit }) {
  const { closeModal } = useModalStore();
  const { startLoading, finishLoading } = useLoadingStore();

  const { ...actions } = useSpotsStore();

  const { errors, handleSubmit, register, reset } = useForm();

  useEffect(() => {
    reset({
      type: "유형",
      customerName: "수하인명",
      customerMobile: "수하인연락처",
      customerMobile2: "",
      customerAddress: "수하인주소",
      customerAddressDetail: "수하인상세주소",
      customerAddressPostalCode: "우편번호",
      prepaid: "선불",
      productName: "제품명",
      productCategory: "제품종류",
      productPrice: "제품가격",
      productCount: "수량",
      memoFromCustomer: "고객전달사항",
      frontdoorPassword: "공동현관비밀번호",
      orderIdFromCorp: "기업고유번호",
      etc1: "기타사항1",
      etc2: "기타사항2",
      etc3: "기타사항3",
      ...spot?.excelSchemeParsed,
    });
  }, [spot]);

  const onSubmit = async (data) => {
    try {
      startLoading();

      const response = await actions.updateExcelScheme(spot.id, data);

      if (response.success) {
        window.alert("대량 접수 파일 설정이 완료되었습니다.");
        closeModal();
        onAfterSubmit();
      } else {
        window.alert(`대량 접수 파일 설정에 실패하였습니다.`);
      }
    } catch (e) {
      window.alert(`대량 접수 파일 설정에 실패하였습니다.\n${e.message}`);
    }

    finishLoading();
  };

  return (
    <Modal>
      <Modal.Header closeButton={false}>
        <Title>대량 접수 엑셀파일 1행 명칭 설정</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              받는분 성명<span>*</span>
            </FormLabel>
            <FormCol>
              <FormControl
                name="customerName"
                ref={register({ required: true })}
              />
              {errors.customerName ? (
                <ErrorText>받는분 성명을 입력해주세요.</ErrorText>
              ) : (
                <FormText>필수값</FormText>
              )}
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              받는분 연락처1<span>*</span>
            </FormLabel>
            <FormCol>
              <FormControl
                name="customerMobile"
                ref={register({ required: true })}
              />
              {errors.customerMobile ? (
                <ErrorText>받는분 연락처를 입력해주세요.</ErrorText>
              ) : (
                <FormText>필수값</FormText>
              )}
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              받는분 연락처2
            </FormLabel>
            <FormCol>
              <FormControl name="customerMobile2" ref={register} />
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              받는분 주소<span>*</span>
            </FormLabel>
            <FormCol>
              <FormControl
                name="customerAddress"
                ref={register({ required: true })}
              />
              {errors.customerAddress ? (
                <ErrorText>받는분 주소를 입력해주세요.</ErrorText>
              ) : (
                <FormText>필수값</FormText>
              )}
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              받는분 상세주소
            </FormLabel>
            <FormCol>
              <FormControl name="customerAddressDetail" ref={register} />
              <FormText>주소값 분리 입력 필요시 사용해주십시오.</FormText>
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              우편번호
            </FormLabel>
            <FormCol>
              <FormControl name="customerAddressPostalCode" ref={register} />
              <FormText>5자리 우편번호만 입력해주세요.</FormText>
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              주문번호
            </FormLabel>
            <FormCol>
              <FormControl name="orderIdFromCorp" ref={register} />
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              상품명
            </FormLabel>
            <FormCol>
              <FormControl name="productName" ref={register} />
              <FormText>운송장에 표시됩니다.</FormText>
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              수량
            </FormLabel>
            <FormCol>
              <FormControl name="productCount" ref={register} />
              <FormText>운송장 상품명과 추가표시 우측에 표시됩니다.</FormText>
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              가격
            </FormLabel>
            <FormCol>
              <FormControl name="productPrice" ref={register} />
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              고객요청메시지
            </FormLabel>
            <FormCol>
              <FormControl name="memoFromCustomer" ref={register} />
              <FormText>운송장에 표시됩니다.</FormText>
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              공동현관비밀번호
            </FormLabel>
            <FormCol>
              <FormControl name="frontdoorPassword" ref={register} />
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              상품명 앞 추가표기
            </FormLabel>
            <FormCol>
              <FormControl name="etc3" ref={register} />
              <FormText>운송장 상품명 좌측에 표시됩니다.</FormText>
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              상품명 뒤 추가표기1
            </FormLabel>
            <FormCol>
              <FormControl name="etc1" ref={register} />
              <FormText>운송장 상품명 우측에 표시됩니다.</FormText>
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              상품명 뒤 추가표기2
            </FormLabel>
            <FormCol>
              <FormControl name="etc2" ref={register} />
              <FormText>운송장 추가표기1 우측에 표시됩니다.</FormText>
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormLabel column xs="4">
              회송전용배송 구분
            </FormLabel>
            <FormCol>
              <FormControl name="type" ref={register} />
              <FormText>회송전용배송 구분을 위한 열 1행 값입니다.</FormText>
            </FormCol>
          </FormGroup>
          <FormGroup as={Row}>
            <FormCol xs={{ span: 8, offset: 4 }}>
              <Button type="submit" width="140px">
                변경
              </Button>
              <Button
                type="button"
                width="140px"
                variant="secondary"
                style={{ marginLeft: "5px" }}
                onClick={closeModal}
              >
                취소
              </Button>
            </FormCol>
          </FormGroup>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
