import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { saveAs } from "file-saver";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Guide from "@/components/Guide";
import Table from "@/components/Table";

import DeliveryModal from "@/modals/DeliveryModal";
import WaybillModal from "@/modals/WaybillModal";

import Delivery from "@/Models/Delivery";

import {
  useAgPostalCodesStore,
  useDeliveriesStore,
  useLoadingStore,
  useModalStore,
  useProfileStore,
} from "@/stores/hooks";
import { QUERY_STATUS } from "@/stores/Deliveries";

const BodyContent = styled.div``;
const Container = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
`;

const SearchWrap = styled.div`
  padding-top: 15px;
`;
const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;
const Column = styled(Col)`
  display: flex;
  align-items: center;
`;
const ColumnRight = styled(Column)`
  justify-content: flex-end;
`;
const SearchButton = styled(Button)`
  margin-left: 5px;
`;
const FormControl = styled(Form.Control)`
  width: ${({ width }) => width || 180}px !important;
`;
const Wave = styled.span`
  margin: 0 5px;
`;
const SearchLabel = styled.div`
  font-weight: bold;
  margin-right: 10px;
`;

const TableWrap = styled.div``;
const ColumnLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

const BookIdColumn = memo(({ bookId, prefix, onAfterSubmit }) => {
  const { openModal } = useModalStore();

  const handleClick = useCallback(async () => {
    openModal(
      <DeliveryModal
        key={`delivery-${bookId}-modal`}
        bookId={bookId}
        onAfterSubmit={onAfterSubmit}
      />,
    );
  }, [bookId, onAfterSubmit]);

  return (
    <ColumnLink onClick={handleClick}>
      {prefix}
      {bookId}
    </ColumnLink>
  );
});

export default function Deliveries() {
  const { agPostalCodes } = useAgPostalCodesStore().state;
  const { ...agPostalCodesActions } = useAgPostalCodesStore();

  const { state, ...actions } = useDeliveriesStore();
  const { startLoading, finishLoading } = useLoadingStore();
  const { openModal } = useModalStore();

  const { user } = useProfileStore().state;
  const { ...profileActions } = useProfileStore();

  const { handleSubmit, register, reset } = useForm();

  const [selectedDeliveries, setSelectedDeliveries] = useState([]);

  const fetchAll = useCallback(async () => {
    try {
      startLoading();
      await actions.fetchAll({ isDrx: user.isDrx });
    } catch (_e) {}

    finishLoading();
  }, [actions.fetchAll, user.isDrx]);

  const fetchCountByStatus = useCallback(async () => {
    try {
      await actions.countByStatus({ isDrx: user.isDrx });
    } catch (e) {
      console.log(e);
    }
  }, [actions.countByStatus, user.isDrx]);

  useEffect(() => {
    reset({
      status: state.query.status,
    });
  }, []);

  useEffect(() => {
    fetchCountByStatus();
    fetchAgPostalCodes();
    fetchUser();
  }, []);

  useEffect(() => {
    if (state.query.page > 1) {
      actions.setQuery({ page: 1 });
    } else {
      fetchAll();
    }
  }, [state.query.status]);

  useEffect(() => {
    fetchAll();
  }, [
    state.query.page,
    state.query.pageSize,
    state.query.sortKey,
    state.query.sortType,
  ]);

  const fetchAgPostalCodes = useCallback(async () => {
    if (!agPostalCodes.length) {
      try {
        await agPostalCodesActions.fetchAll();
      } catch (e) {
        console.log(e);
      }
    }
  }, [agPostalCodesActions.fetchAll, agPostalCodes]);

  const fetchUser = useCallback(async () => {
    try {
      await profileActions.fetchUser();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleChange = useCallback(
    (e) => {
      actions.setQuery({ [e.target.name]: e.target.value });

      if (e.target.name === "dateFrom" && e.target.value > state.query.dateTo) {
        actions.setQuery({
          dateTo: e.target.value,
        });
      }

      if (e.target.name === "dateTo" && e.target.value < state.query.dateFrom) {
        actions.setQuery({
          dateFrom: e.target.value,
        });
      }
    },
    [actions.setQuery, state.query],
  );

  const handleChangeStatus = useCallback(
    (e) => {
      actions.setQuery({ [e.target.name]: e.target.value });
    },
    [actions.setQuery],
  );

  const handleClickHeader = useCallback(
    ({ sortKey, sortType }) => {
      actions.setQuery({ sortKey, sortType });
    },
    [actions.setQuery],
  );

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const handleSelect = useCallback(({ selectedRows }) => {
    setSelectedDeliveries(selectedRows);
  }, []);

  /**
   * DRX 송장전달
   */
  const handleClickCallbackDrx = useCallback(async () => {
    const selectedCount = selectedDeliveries.length;

    if (!selectedCount) {
      return;
    }

    if (!!selectedDeliveries.find((e) => !e.etc1)) {
      window.alert(
        "기타사항1 값이 누락된 선택 접수건이 있습니다. 확인 후 재시도해주십시오.",
      );
      return;
    }

    if (
      window.confirm(
        `선택한 ${selectedCount}건의 우체국택배 운송장번호를 전달하시겠습니까?`,
      )
    ) {
      try {
        startLoading();
        const response = await actions.callbackDrx(selectedDeliveries);

        const fails = response.filter(
          (el) => el.status === "rejected" || !el.value.success,
        );

        let text = `우체국택배운송장번호 전달 ${
          selectedDeliveries.length
        }건 중 ${selectedCount - fails.length}건 성공.\n\n실패 목록`;

        response.forEach((el, idx) => {
          if (el.status === "rejected" || !el.value?.success) {
            const _bookId = `\n${selectedDeliveries[idx].bookId}`;

            text += `${_bookId}: ${
              el.status === "rejected"
                ? el.reason?.message
                : el.value?.response?.err_msg
            }`;
          }
        });

        window.alert(text);
      } catch (e) {
        window.alert(
          `우체국택배 운송장번호 전달에 실패하였습니다.\n${e.message}`,
        );
      }
      finishLoading();
    }
  }, [selectedDeliveries]);

  /**
   * 목록 다운로드
   */
  const handleClickDownload = useCallback(async () => {
    try {
      startLoading();

      const result = await actions.download(selectedDeliveries);

      saveAs(
        result,
        `배송목록_${state.query.dateFrom}_${state.query.dateTo}.xlsx`,
      );
    } catch (e) {
      window.alert(`목록다운에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [actions.download, selectedDeliveries]);

  /**
   * 복수건 취소 처리
   */
  const handleClickCancelBulk = useCallback(async () => {
    const selectedCount = selectedDeliveries.length;

    if (!selectedCount) {
      return;
    }

    if (window.confirm(`선택한 ${selectedCount}건을 모두 취소하시겠습니까?`)) {
      try {
        startLoading();
        const response = await actions.cancelBulk(selectedDeliveries);

        const fails = response.filter((el) => el.status === "rejected");

        window.alert(
          `${selectedDeliveries.length}건 중 ${
            selectedCount - fails.length
          }건을 취소했습니다.\n${
            fails.length > 0
              ? `취소 실패 사유: ${fails[0].reason?.message}`
              : ""
          }`,
        );

        // window.alert(
        //   `${selectedDeliveries.length}건 중 ${
        //     selectedDeliveries.length - bookIdsUnableToCancel.length
        //   }건을 취소했습니다.\n수거완료되었거나 이미 취소된 경우에는 취소가 불가능하오니 체인로지스 관제팀으로 문의주십시오.\n취소실패(${
        //     bookIdsUnableToCancel.length
        //   })건${bookIdsUnableToCancel.length ? ` ${bookIdsUnableToCancel.join(', ')}` : ''}`
        // );

        fetchAll();
        fetchCountByStatus();
      } catch (e) {
        window.alert(`선택 취소에 실패하였습니다.\n${e.message}`);
      }
      finishLoading();
    }
  }, [actions.cancelBulk, selectedDeliveries, fetchAll, fetchCountByStatus]);

  /**
   * 복수건 복제 접수
   */
  const handleClickCopyBulk = useCallback(async () => {
    const selectedCount = selectedDeliveries.length;

    if (!selectedCount) {
      return;
    }

    if (
      window.confirm(`선택한 ${selectedCount}건을 모두 복제접수하시겠습니까?`)
    ) {
      try {
        startLoading();
        const response = await actions.copyBulk(selectedDeliveries);

        const fails = response.filter((el) => el.status === "rejected");
        const success = response.filter((el) => el.status === "fulfilled");

        window.alert(
          `${selectedDeliveries.length}건 중 ${
            success.length
          }건을 복제접수했습니다.\n${
            fails.length > 0
              ? `복제접수 실패 사유: ${fails[0].reason?.message}`
              : ""
          }`,
        );

        fetchAll();
        fetchCountByStatus();

        if (success.length > 0) {
          openModal(
            <WaybillModal
              key="waybill-modal"
              deliveries={success.map((el) => new Delivery(el.value.delivery))}
              onAfterSubmit={fetchAll}
            />,
          );
        }
      } catch (e) {
        window.alert(`복제접수에 실패하였습니다.\n${e.message}`);
      }
      finishLoading();
    }
  }, [selectedDeliveries, fetchAll, fetchCountByStatus]);

  /**
   * 복수건 반품 처리
   */
  const handleClickReturnBulk = useCallback(async () => {
    const selectedCount = selectedDeliveries.length;

    if (!selectedCount) {
      return;
    }

    if (window.confirm(`선택한 ${selectedCount}건을 모두 반품하시겠습니까?`)) {
      try {
        startLoading();
        const response = await actions.returnBulk(selectedDeliveries);

        const fails = response.filter((el) => el.status === "rejected");

        window.alert(
          `${selectedDeliveries.length}건 중 ${
            selectedCount - fails.length
          }건을 반품했습니다.\n이미 반품이 접수되었거나, 배송완료전, 반송완료, 취소된 접수건은 반품접수가 반품이 불가능하오니 체인로지스 관제팀으로 문의주십시오.${
            fails.length > 0
              ? `\n반품 실패 사유: ${fails[0].reason?.message}`
              : ""
          }`,
        );

        // window.alert(
        //   `${selectedDeliveries.length}건 중 ${
        //     selectedDeliveries.length - bookIdsUnableToReturn.length
        //   }건을 반품했습니다.\n이미 반품이 접수되었거나, 배송완료전, 반송완료, 취소된 접수건은 반품접수가 반품이 불가능하오니 체인로지스 관제팀으로 문의주십시오.\n반품실패(${
        //     bookIdsUnableToReturn.length
        //   })건${bookIdsUnableToReturn.length ? ` ${bookIdsUnableToReturn.join(', ')}` : ''}`
        // );

        fetchAll();
        fetchCountByStatus();
      } catch (e) {
        window.alert(`선택 반품에 실패하였습니다.\n${e.message}`);
      }
      finishLoading();
    }
  }, [actions.returnBulk, selectedDeliveries, fetchAll, fetchCountByStatus]);

  /**
   * 운송장 출력
   */
  const handleClickPrintToday = useCallback(async () => {
    try {
      const deliveriesToday = await actions.fetchDoneToday();

      if (deliveriesToday?.length > 0) {
        openModal(
          <WaybillModal
            key="waybill-modal"
            deliveries={deliveriesToday}
            onAfterSubmit={fetchAll}
          />,
        );
      } else {
        window.alert("당일 접수건이 0건입니다.");
      }
    } catch (e) {
      window.alert(`당일 접수건 조회에 실패하였습니다.\n${e.message}`);
    }
  }, [actions.fetchDoneToday, fetchAll]);

  const handleClickPrintSelected = useCallback(() => {
    if (selectedDeliveries.length > 0) {
      openModal(
        <WaybillModal
          key="waybill-modal"
          deliveries={selectedDeliveries}
          onAfterSubmit={fetchAll}
        />,
      );
    } else {
      alert("배송건을 선택해주세요.");
    }
  }, [selectedDeliveries]);

  const handleClickPrintNotPrinted = useCallback(async () => {
    try {
      const deliveriesNotPrinted = await actions.fetchNotPrinted();

      if (deliveriesNotPrinted?.length > 0) {
        openModal(
          <WaybillModal
            key="waybill-modal"
            deliveries={deliveriesNotPrinted}
            onAfterSubmit={fetchAll}
          />,
        );
      } else {
        window.alert("미출력 접수건이 0건입니다.");
      }
    } catch (e) {
      window.alert(`미출력 접수건 조회에 실패하였습니다.\n${e.message}`);
    }
  }, [actions.fetchNotPrinted]);

  const onSubmit = useCallback(() => {
    fetchAll();
    fetchCountByStatus();
  }, [fetchAll, fetchCountByStatus]);

  const statuses = [
    {
      label: "전체",
      value: QUERY_STATUS.ALL,
      count: state.count?.all,
    },
    {
      label: "접수",
      value: QUERY_STATUS.RECEIPTED,
      count: state.count?.receipted,
    },
    {
      label: "수거대기",
      value: QUERY_STATUS.PICKUP_SCHEDULED,
      count: state.count?.pickupScheduled,
    },
    {
      label: "배차중",
      value: QUERY_STATUS.DELIVERY_ALLOCATED,
      count: state.count?.deliveryAllocated,
    },
    {
      label: "배송연기",
      value: QUERY_STATUS.POSTPONED,
      count: state.count?.postponed,
    },
    {
      label: "배송중",
      value: QUERY_STATUS.DELIVERY_STARTED,
      count: state.count?.deliveryStarted,
    },
    {
      label: "배송완료",
      value: QUERY_STATUS.DELIVERY_COMPLETED,
      count: state.count?.deliveryCompleted,
    },
    {
      label: "반송완료",
      value: QUERY_STATUS.SENT_BACK_COMPLETED,
      count: state.count?.sentBack,
    },
    {
      label: "분실완료",
      value: QUERY_STATUS.LOST_COMPLETED,
      count: state.count?.lost,
    },
    {
      label: "픽업",
      value: QUERY_STATUS.TYPE_PICKUP,
      count: state.count?.typePickup,
    },
    {
      label: "픽업건수거완료",
      value: QUERY_STATUS.PICKUP_COMPLETED_TYPE_PICKUP,
      count: state.count?.pickupCompletedTypePickup,
    },
    {
      label: "반품",
      value: QUERY_STATUS.TYPE_RETURN,
      count: state.count?.typeReturn,
    },
    {
      label: "취소",
      value: QUERY_STATUS.CANCELED,
      count: state.count?.canceled,
    },
    {
      label: "사고",
      value: QUERY_STATUS.ACCIDENT,
      count: state.count?.accident,
    },
    {
      label: "송하인주소오류",
      value: QUERY_STATUS.SENDER_ADDRESS_ERROR,
      count: state.count?.senderAddressError,
    },
    {
      label: "송하인주소오류취소",
      value: QUERY_STATUS.SENDER_ADDRESS_ERROR_CANCELED,
      count: state.count?.senderAddressErrorCanceled,
    },
    {
      label: "수하인주소오류",
      value: QUERY_STATUS.RECEIVER_ADDRESS_ERROR,
      count: state.count?.receiverAddressError,
    },
    {
      label: "수하인주소오류취소",
      value: QUERY_STATUS.RECEIVER_ADDRESS_ERROR_CANCELED,
      count: state.count?.receiverAddressErrorCanceled,
    },
    {
      label: "미배송",
      value: QUERY_STATUS.DELAYED,
      count: state.count?.delayed,
    },
    {
      label: "배송불가지역",
      value: QUERY_STATUS.ADDRESS_NOT_SUPPORTED,
      count: state.count?.addressNotSupported,
    },
    {
      label: "배송불가지역취소",
      value: QUERY_STATUS.ADDRESS_NOT_SUPPORTED_CANCELED,
      count: state.count?.addressNotSupportedCanceled,
    },
  ];

  // if (user.isDrx) {
  //   statuses.push({
  //     label: "타배송사접수",
  //     value: QUERY_STATUS.ADDRESS_NOT_SUPPORTED_WITH_BUSAN,
  //     count: state.count?.addressNotSupportedWithBusan,
  //   });
  //   statuses.push({
  //     label: "타배송사접수취소",
  //     value: QUERY_STATUS.ADDRESS_NOT_SUPPORTED_WITH_BUSAN_CANCELED,
  //     count: state.count?.addressNotSupportedWithBusanCanceled,
  //   });
  // }

  let _columns = [
    {
      Header: "사측주문번호",
      accessor: "orderIdFromCorp",
      id: "orderIdFromCorp",
      sort: true,
      width: 110,
    },
    {
      Header: "예약번호",
      accessor: (row) => {
        return (
          <BookIdColumn
            bookId={row.bookId}
            prefix={row.renderBookIdPrefix()}
            onAfterSubmit={fetchAll}
          />
        );
      },
      width: 110,
    },
    {
      Header: "접수일시",
      accessor: (row) => row.renderReceiptDate(),
      id: "receiptDate",
      sort: true,
      width: 100,
    },
    {
      Header: "접수자",
      accessor: "senderName",
      width: 80,
    },
    {
      Header: "접수점",
      accessor: "spotName",
      width: 80,
    },
    {
      Header: "고객명",
      accessor: "receiverName",
      width: 80,
    },
  ];

  if (user?.showRequestedDeliveryTime) {
    _columns.push({
      Header: "배송요청일시",
      accessor: (row) => row.renderRequestedDeliveryTime(),
      id: "requestedDeliveryTime",
      sort: true,
      width: 100,
    });
  }

  _columns = [
    ..._columns,
    {
      Header: "상품명",
      accessor: "productName",
      width: 100,
    },
    {
      Header: "기타사항1",
      accessor: "etc1",
      width: 80,
    },
    {
      Header: "기타사항2",
      accessor: "etc2",
      width: 80,
    },
    {
      Header: "기타사항3",
      accessor: "etc3",
      width: 80,
    },
    {
      Header: "배송연기",
      accessor: (row) => row.renderPostponedDate(),
      width: 100,
    },
    {
      Header: "수거완료",
      accessor: (row) => row.renderPickupDateCompleted(),
      width: 100,
    },
    {
      Header: "배송중",
      accessor: (row) => row.renderReleasedAt(),
      width: 100,
    },

    {
      Header: "배송완료",
      accessor: (row) => row.renderDeliveryCompletedDate(),
      id: "deliveryCompletedDate",
      sort: true,
      width: 100,
    },
    {
      Header: "취소",
      accessor: (row) => row.renderCanceledAt(),
      width: 100,
    },
    {
      Header: "분실",
      accessor: (row) => row.renderLostDate(),
      width: 100,
    },
    {
      Header: "반송",
      accessor: (row) => row.renderSentBackDate(),
      width: 100,
    },
    {
      Header: "미배송목록",
      accessor: (row) => row.renderDelayedDeliveries(),
      width: 100,
    },
    {
      Header: "운송장출력여부",
      accessor: (row) => (row.printed ? "O" : "X"),
      width: 80,
    },
  ];

  const columns = useMemo(() => _columns, [fetchAll]);

  return (
    <BodyContent>
      <Guide />
      <Container>
        <SearchWrap>
          <SearchRow>
            <Column xs={8}>
              <Form inline onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  width={140}
                  as="select"
                  name="senderName"
                  onChange={handleChange}
                  custom
                >
                  <option value="">지점 전체</option>
                  {user?.senderNames?.map((el, index) => {
                    return (
                      <option value={el} key={index}>
                        {el}
                      </option>
                    );
                  })}
                </FormControl>
                <Wave></Wave>
                <FormControl
                  width={140}
                  as="select"
                  name="region"
                  onChange={handleChange}
                  custom
                >
                  <option value="">지역 전체</option>
                  <option value="SEOUL_INCHEON_GYUNGGI">서울/인천/경기</option>
                  <option value="BUSAN_GYUNGNAM">부산/경남</option>
                </FormControl>
                <Wave></Wave>
                <FormControl
                  name="dateFrom"
                  onChange={handleChange}
                  type="date"
                  value={state.query.dateFrom}
                />
                <Wave>~</Wave>
                <FormControl
                  name="dateTo"
                  onChange={handleChange}
                  type="date"
                  value={state.query.dateTo}
                />
                <SearchButton type="submit" width="100px">
                  조회
                </SearchButton>
              </Form>
            </Column>
            <ColumnRight xs={4}>
              <Form inline onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  width={260}
                  name="query"
                  onChange={handleChange}
                  placeholder="예약번호, 수하인명, 사측주문번호 조회"
                  value={state.query.query}
                />
                <SearchButton type="submit" width="100px">
                  조회
                </SearchButton>
              </Form>
            </ColumnRight>
          </SearchRow>
          <SearchRow>
            <Column xs={7}>
              <Form onChange={handleChangeStatus}>
                {statuses.map((status) => {
                  return (
                    <Form.Check
                      inline
                      label={`${status.label}(${status.count})`}
                      type="radio"
                      id={`status-${status.value}`}
                      value={status.value}
                      name="status"
                      key={status.label}
                      ref={register}
                    />
                  );
                })}
              </Form>
            </Column>
            <ColumnRight xs={5}>
              <SearchLabel>전체 총 {state.totalCount} 조회</SearchLabel>
              {user.isDrx && (
                <SearchButton
                  type="button"
                  variant="outline-secondary"
                  width="130px"
                  onClick={handleClickCallbackDrx}
                >
                  불가지역송장전달
                </SearchButton>
              )}

              <SearchButton
                type="button"
                variant="outline-secondary"
                width="80px"
                onClick={handleClickCopyBulk}
              >
                복제접수
              </SearchButton>
              <SearchButton
                type="button"
                variant="outline-secondary"
                width="80px"
                onClick={handleClickDownload}
              >
                목록다운
              </SearchButton>
              <SearchButton
                type="button"
                variant="outline-secondary"
                width="80px"
                onClick={handleClickCancelBulk}
              >
                선택취소
              </SearchButton>
              <SearchButton
                type="button"
                variant="outline-secondary"
                width="80px"
                onClick={handleClickReturnBulk}
              >
                선택반품
              </SearchButton>
            </ColumnRight>
          </SearchRow>
          <SearchRow>
            <ColumnRight>
              <SearchLabel>운송장 출력</SearchLabel>
              <SearchButton
                onClick={handleClickPrintToday}
                type="button"
                variant="outline-secondary"
                width="80px"
              >
                당일건
              </SearchButton>
              <SearchButton
                onClick={handleClickPrintSelected}
                type="button"
                variant="outline-secondary"
                width="80px"
              >
                선택건
              </SearchButton>
              <SearchButton
                onClick={handleClickPrintNotPrinted}
                type="button"
                variant="outline-secondary"
                width="80px"
              >
                미출력건
              </SearchButton>
            </ColumnRight>
          </SearchRow>
        </SearchWrap>
        <TableWrap>
          <Table
            responsive
            bordered
            hover
            columns={columns}
            currentPage={state.query.page}
            data={state.deliveries}
            goToPage={handleGoToPage}
            nextPage={handleNextPage}
            onClickHeader={handleClickHeader}
            onSelect={handleSelect}
            pageCount={state.pageCount}
            pageSize={state.query.pageSize}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </TableWrap>
      </Container>
    </BodyContent>
  );
}
