import React, { memo, useEffect, useState, useCallback } from "react";
import _noop from "lodash/noop";
import table from "react-bootstrap/Table";
import {
  usePagination,
  useRowSelect,
  useTable,
  useSortBy,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import styled from "@emotion/styled";
import Pagination from "@/components/Table/Pagination";
import IndeterminateCheckbox from "./IndeterminateCheckbox";

const Table = styled(table)`
  display: inline-block;
`;

const SORTING_ASC = "SORTING_ASC";
const SORTING_DESC = "SORTING_DESC";
const INITIAL_SORTING_STATE = {
  key: "",
  sorting: "",
};

const convertSortingQueryString = ({
  key,
  sorting,
} = INITIAL_SORTING_STATE) => {
  if (key === "") {
    return {
      sortKey: "",
      sortType: "DESC",
    };
  }

  switch (sorting) {
    case SORTING_ASC:
      return {
        sortKey: key,
        sortType: "ASC",
      };
    case SORTING_DESC:
      return {
        sortKey: key,
        sortType: "DESC",
      };
    default:
      return {
        sortKey: "",
        sortType: "DESC",
      };
  }
};

const Thead = styled.thead`
  tr {
    border-left: 1px solid #999;
  }

  th {
    cursor: pointer;
    position: relative;
    min-width: 30px;
    max-width: 400px;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
    padding: 0.5rem;
    border-top: 1px solid #999;
    border-right: 1px solid #999;
    border-bottom: none;
    border-left: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;

    .resizer {
      display: inline-block;
      width: 10px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;
      touch-action: none;
    }
  }
`;

const Tbody = styled.tbody`
  border-bottom: 1px solid #999;

  tr {
    border-left: 1px solid #999;

    &:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  td {
    position: relative;
    min-width: 30px;
    max-width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
    padding: 0.5rem;
    border-top: 1px solid #999;
    border-right: 1px solid #999;
    border-bottom: none;
    border-left: none;
  }
`;

const SortingArrow = memo(({ sorting }) => {
  switch (sorting) {
    case SORTING_ASC:
      return "🔼";
    case SORTING_DESC:
      return "🔽";
    default:
      throw new Error("잘못된 소팅값입니다");
  }
});

const Td = memo(({ children, row, selectable = true, ...props }) => {
  const handleClick = useCallback(() => {
    if (selectable) {
      row.toggleRowSelected();
    }
  }, [row]);

  return (
    <td {...props} onClick={handleClick}>
      {children}
    </td>
  );
});

const Th = memo(({ children, column, onClickHeader }) => {
  const handleClick = useCallback(() => {
    onClickHeader(column);
  }, [column, onClickHeader]);

  return (
    <th {...column.getHeaderProps()} onClick={handleClick}>
      {children}
    </th>
  );
});

export default memo(
  ({
    checkbox = true,
    columns,
    currentPage = 1,
    data,
    goToPage,
    onClickHeader = _noop,
    onSelect,
    nextPage,
    pageCount = 1,
    pageSize = 10,
    pagination = true,
    previousPage,
    setPageSize,
    ...restProps
  }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      pageOptions,
      selectedFlatRows,
      state: { selectedRowIds },
      toggleAllRowsSelected,
    } = useTable(
      {
        columns,
        data,
        manualPagination: true,
        pageCount,
      },
      useSortBy,
      usePagination,
      useRowSelect,
      useBlockLayout,
      useResizeColumns,
      (hooks) => {
        if (checkbox) {
          hooks.visibleColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              ),
              width: 30,
            },
            ...columns,
          ]);
        } else {
          hooks.visibleColumns.push((columns) => [...columns]);
        }
      },
    );

    const [sortingState, setSortingState] = useState(INITIAL_SORTING_STATE);

    const handleClickHeader = useCallback(
      (column) => {
        if (column.id === "selection") {
          toggleAllRowsSelected();
          return;
        }

        if (!column.sort) {
          return;
        }

        let _sortingState = { ...sortingState };

        if (sortingState.key !== column.id) {
          _sortingState.key = column.id;
          _sortingState.sorting = SORTING_ASC;
        } else {
          switch (sortingState.sorting) {
            case SORTING_ASC:
              _sortingState.sorting = SORTING_DESC;
              break;
            case SORTING_DESC:
              _sortingState = INITIAL_SORTING_STATE;
              break;
            default:
              throw new Error("잘못된 소팅값입니다");
          }
        }

        setSortingState(_sortingState);
        onClickHeader(convertSortingQueryString(_sortingState));
      },
      [
        onClickHeader,
        setSortingState,
        sortingState.key,
        sortingState.sorting,
        toggleAllRowsSelected,
      ],
    );

    useEffect(() => {
      if (onSelect) {
        onSelect({ selectedRows: selectedFlatRows.map((row) => row.original) });
      }
    }, [selectedRowIds]);

    return (
      <>
        <Table {...getTableProps()} {...restProps}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    key={column.id}
                    column={column}
                    onClickHeader={handleClickHeader}
                  >
                    {column.render("Header")}
                    {column.id === sortingState.key && (
                      <SortingArrow sorting={sortingState.sorting} />
                    )}
                    {column.id !== "selection" && (
                      <div {...column.getResizerProps()} className="resizer" />
                    )}
                  </Th>
                ))}
              </tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        row={row}
                        selectable={
                          cell.column.id === "selection" ||
                          cell.column.selectable !== false
                        }
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </tr>
              );
            })}
          </Tbody>
        </Table>
        {pagination && (
          <Pagination
            canNextPage={currentPage < pageCount}
            canPreviousPage={currentPage > 1}
            nextPage={nextPage}
            gotoPage={goToPage}
            pageCount={pageCount}
            pageIndex={currentPage - 1}
            pageOptions={pageOptions}
            pageSize={pageSize}
            previousPage={previousPage}
            setPageSize={setPageSize}
          />
        )}
      </>
    );
  },
);
