import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import Router from "./Router";
import { Global, css } from "@emotion/react";

import {
  AgPostalCodesStore,
  AuthStore,
  DeliveriesStore,
  DeliveriesNewStore,
  LoadingStore,
  ModalStore,
  PopupsStore,
  ProfileStore,
  SpotsStore,
} from "./stores";

import "bootstrap/dist/css/bootstrap.min.css";

const GlobalStyle = css`
  body {
    margin: 0;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
    font-size: 14px;
  }

  a,
  a:hover {
    color: inherit;
  }

  .modal {
    .modal-dialog {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      min-width: 100%;
      margin: 0;
      flex-direction: column;
    }

    .modal-content {
      width: 600px;
      border-radius: 0;
      flex: none;
      padding: 0 10px;
      postion: relative;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    .modal-lg {
      .modal-content {
        width: 800px;
      }
    }

    .modal-header {
      .close {
        padding: 13px;

        span {
          font-size: 26px;
        }
      }
    }

    .modal-body {
      max-height: calc(100vh - 200px);
      overflow: auto;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const AppProvider = ({ contexts, children }) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
      }),
    children,
  );

function App() {
  return (
    <>
      <Global styles={GlobalStyle} />
      <AppProvider
        contexts={[
          AgPostalCodesStore.Provider,
          AuthStore.Provider,
          DeliveriesStore.Provider,
          DeliveriesNewStore.Provider,
          LoadingStore.Provider,
          ModalStore.Provider,
          PopupsStore.Provider,
          ProfileStore.Provider,
          SpotsStore.Provider,
        ]}
      >
        <BrowserRouter>
          <RecoilRoot>
            <Router />
          </RecoilRoot>
        </BrowserRouter>
      </AppProvider>
    </>
  );
}

export default App;
