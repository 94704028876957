import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import Delivery, { DELIVERY_TYPE_NORMAL } from "@/Models/Delivery";
import DeliveryForExcel from "@/Models/DeliveryForExcel";

import api from "../Api";

export const CONSTANTS = {
  SET_DELIVERY_TYPE: "SET_DELIVERY_TYPE",
  BULK_APPLY: "BULK_APPLY",
  RESET_BULK: "RESET_BULK",
};

const INITIAL_STATE = {
  bulkApplied: false,
  bulkAppliedCount: 0,
  bulkDeliveriesFailed: [],
  bulkDeliveriesFailedReason: null,
  bulkDeliveriesForDownload: [],
  bulkDeliveriesForPrint: [],
  deliveryType: DELIVERY_TYPE_NORMAL,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.SET_DELIVERY_TYPE:
      return {
        ...state,
        deliveryType: action.deliveryType,
      };
    case CONSTANTS.BULK_APPLY:
      return {
        ...state,
        bulkApplied: action.bulkApplied,
        bulkAppliedCount: action.bulkAppliedCount,
        bulkDeliveriesFailed: action.bulkDeliveriesFailed,
        bulkDeliveriesFailedReason: action.bulkDeliveriesFailedReason,
        bulkDeliveriesForDownload: action.bulkDeliveriesForDownload,
        bulkDeliveriesForPrint: action.bulkDeliveriesForPrint,
      };
    case CONSTANTS.RESET_BULK:
      return {
        ...state,
        bulkApplied: false,
        bulkAppliedCount: 0,
        bulkDeliveries: [],
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveriesNewStore = () => {
  const { dispatch, state } = useContext(Context);

  const setDeliveryType = useCallback(
    (deliveryType) => {
      dispatch({ type: CONSTANTS.SET_DELIVERY_TYPE, deliveryType });
    },
    [dispatch],
  );

  const create = useCallback(
    async (data) => {
      const response = await api.post(`/deliveries`, data);

      return new Delivery(response);
    },
    [api.post],
  );

  const createBulk = useCallback(
    async ({ data, spotId }) => {
      const response = await api.post("/deliveries/worksheet", {
        type: "1",
        rows: data,
        spotId,
      });

      dispatch({
        type: CONSTANTS.BULK_APPLY,
        bulkApplied: true,
        bulkAppliedCount: data.length,
        bulkDeliveriesFailed: response?.failed || [],
        bulkDeliveriesFailedReason: response?.failed[0]?.reason,
        bulkDeliveriesForDownload: response?.deliveriesForDownload
          ? response?.deliveriesForDownload.map((d) => new DeliveryForExcel(d))
          : [],
        bulkDeliveriesForPrint:
          response?.deliveriesForPrint.map((d) => new Delivery(d)) || [],
      });

      return response;
    },
    [dispatch, api.post],
  );

  const createReturnBulk = useCallback(
    async (data) => {
      const response = await api.post("/deliveries/worksheet/returns", {
        type: "3",
        rows: data,
      });

      // dispatch({
      //   type: CONSTANTS.BULK_APPLY,
      //   bulkApplied: true,
      //   bulkAppliedCount: data.length,
      //   bulkDeliveriesFailed: response?.failed?.row || [],
      //   bulkDeliveriesFailedReason: response?.failed?.reason,
      //   bulkDeliveriesForDownload: response?.deliveriesForDownload
      //     ? response?.deliveriesForDownload.map((d) => new DeliveryForExcel(d))
      //     : [],
      //   bulkDeliveriesForPrint: response?.deliveriesForPrint || [],
      // });

      return response;
    },
    [dispatch, api.post],
  );

  const resetBulk = useCallback(async () => {
    dispatch({
      type: CONSTANTS.RESET_BULK,
    });
  }, [dispatch]);

  const download = useCallback(
    (bookIds, type) => {
      return api.get("/deliveries/download/book-ids", {
        bookIds: bookIds.join(","),
        type,
      });
    },
    [api.get],
  );

  const updateBulk = useCallback(
    async (data) => {
      const response = await api.put("/deliveries/worksheet", {
        type: "1",
        rows: data,
      });

      return response;
    },
    [dispatch, api.post],
  );

  return {
    state,
    create,
    createBulk,
    createReturnBulk,
    download,
    resetBulk,
    setDeliveryType,
    updateBulk,
  };
};
