import queryString from "qs";

// const parseObjToBodyString = (obj) => {
//   return Object.keys(obj)
//     .map((key) => `${key}=${obj[key]}`)
//     .join("&");
// };

const api = {};

api.get = (endpoint, query = {}) => {
  let isJson = true;

  const URL = `${
    process.env.REACT_APP_API_URL
  }${endpoint}?${queryString.stringify(query)}`;
  return fetch(URL, {
    credentials: "include",
    method: "GET",
    mode: "cors",
  })
    .then((res) => {
      var contentType = res.headers.get("content-type");
      if (contentType.includes("openxmlformats")) {
        isJson = false;
        return res.blob();
      } else {
        return res.json();
      }
    })
    .then((res) => {
      if (!isJson) {
        return res;
      }

      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

api.post = (endpoint, body = {}, timeoutMs = 600000) => {
  // 기본값 10분 (600,000ms)
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}`;

  // AbortController 생성
  const controller = new AbortController();
  const signal = controller.signal;

  // 타임아웃 설정
  const timeoutId = setTimeout(() => {
    controller.abort();
  }, timeoutMs);

  return fetch(URL, {
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    mode: "cors",
    signal: signal, // AbortController의 signal 추가
  })
    .then((res) => res.json())
    .then((res) => {
      clearTimeout(timeoutId); // 성공 시 타임아웃 제거

      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    })
    .catch((error) => {
      clearTimeout(timeoutId); // 에러 발생 시에도 타임아웃 제거

      // AbortError(타임아웃으로 인한 중단) 처리
      if (error.name === "AbortError") {
        const timeoutError = new Error("요청 시간이 초과되었습니다 (10분)");
        timeoutError.status = 408; // Request Timeout
        timeoutError.statusCode = 408;
        throw timeoutError;
      }

      throw error;
    });
};

api.postForDownload = (endpoint, body = {}) => {
  let isJson = true;

  const URL = `${process.env.REACT_APP_API_URL}${endpoint}`;
  return fetch(URL, {
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    mode: "cors",
  })
    .then((res) => {
      var contentType = res.headers.get("content-type");
      if (contentType.includes("openxmlformats")) {
        isJson = false;
        return res.blob();
      } else {
        return res.json();
      }
    })
    .then((res) => {
      if (!isJson) {
        return res;
      }

      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

api.put = (endpoint, body = {}) => {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}`;
  return fetch(URL, {
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

api.delete = (endpoint) => {
  const URL = `${process.env.REACT_APP_API_URL}${endpoint}`;
  return fetch(URL, {
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "delete",
    mode: "cors",
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

export default api;
