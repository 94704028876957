import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import api from "../Api";

export const CONSTANTS = {
  FETCH_BY_ID: "FETCH_BY_ID",
};

const INITIAL_STATE = {
  spots: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_BY_ID:
      return {
        ...state,
        spots: [...state.spots, action.spot],
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useSpotsStore = () => {
  const { dispatch, state } = useContext(Context);

  const fetchById = useCallback(
    async (id) => {
      const response = await api.get(`/spots/${id}`);

      if (response?.spot) {
        dispatch({ type: CONSTANTS.FETCH_BY_ID, spot: response.spot });
      }

      return response?.spot;
    },
    [dispatch],
  );

  const updateExcelScheme = useCallback(
    async (id, data) => await api.put(`/spots/${id}/excelscheme`, data),
    [],
  );

  return {
    state,
    updateExcelScheme,
    fetchById,
  };
};
