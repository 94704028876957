import BaseModel from "./base-model";

export default class Spot extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  get excelSchemeParsed() {
    try {
      return (
        this.excelSchemeObj ||
        (this.excelScheme && JSON.parse(this.excelScheme))
      );
    } catch (e) {
      return {};
    }
  }
}
