import BaseModel from "./base-model";

export const USER_NAME_CHAINLOGIS = "chainlogis";
export const USER_NAME_CHAINLOGISAPITEST = "chainlogisapitest";
export const USER_NAME_SK7MOBILE = "sk7mobile";
export const USER_NAME_LGHELLOVISION = "lghellovision";
export const USER_NAME_ZION_USIM = "zion_usim";
export const USER_NAME_DRX = "drx";
export const USER_NAME_THEROOTS3 = "theroots3";

export default class User extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  get editableByWorksheet() {
    return !!this.userEtcObj?.editableByWorksheet || false;
  }

  get userEtcObj() {
    try {
      return this.etc && JSON.parse(this.etc);
    } catch (e) {
      return {};
    }
  }

  get isDrx() {
    return this.username === USER_NAME_DRX;
  }

  get useMasking() {
    return !!this.userEtcObj?.useMasking || false;
  }

  get senderNames() {
    return this.userEtcObj?.senderNames || [];
  }

  get showRequestedDeliveryTime() {
    if (this.etcObj) {
      return this.etcObj.showRequestedDeliveryTime || false;
    }

    return this.userEtcObj?.showRequestedDeliveryTime || false;
  }

  get spotName() {
    return this.spots.length ? this.spots[0].name : "";
  }

  get spotCode() {
    return this.spots.length ? this.spots[0].code : "";
  }

  get spotAddress() {
    return this.spots.length ? this.spots[0].address : "";
  }

  get spotAddressDetail() {
    return this.spots.length ? this.spots[0].addressDetail : "";
  }

  get spotAddressExcelSchemeObj() {
    return this.spots.length ? this.spots[0].excelSchemeObj : null;
  }
  get spotAddressExcelScheme() {
    return this.spots.length ? this.spots[0].excelScheme : "";
  }

  get spotAddressExcelSchemeParsed() {
    try {
      return (
        this.spotAddressExcelSchemeObj ||
        (this.spotAddressExcelScheme && JSON.parse(this.spotAddressExcelScheme))
      );
    } catch (e) {
      return {};
    }
  }

  get updatable() {
    return (
      this.username === USER_NAME_CHAINLOGIS ||
      this.username === USER_NAME_CHAINLOGISAPITEST ||
      this.username === USER_NAME_SK7MOBILE ||
      this.username === USER_NAME_LGHELLOVISION ||
      this.username === USER_NAME_ZION_USIM ||
      this.username === USER_NAME_DRX ||
      this.username === USER_NAME_THEROOTS3
    );
  }
}
