import BaseModel from "./base-model";

import { formatDate, getDateByTimeZone } from "@/lib/date";

export default class SentBackLog extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  renderCreatedAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.createdAt &&
      formatDate(getDateByTimeZone(this.createdAt), dateFormat)
    );
  }
}
